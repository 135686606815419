<script>
  import duppla from '@/duppla'
  import VueRecaptcha from 'vue-recaptcha';
  // import Switcher from "@/components/switcher"
  import {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    HomeIcon,
    UserIcon,
    UserCheckIcon,
    MailIcon,
    KeyIcon,
    PhoneForwardedIcon,
    PhoneIcon,
  } from "vue-feather-icons";

  /**
   * Auth-signup-three component
   */
  export default {
    components: {
      // Switcher,
      FacebookIcon,
      InstagramIcon,
      TwitterIcon,
      LinkedinIcon,
      HomeIcon,
      UserIcon,
      UserCheckIcon,
      MailIcon,
      KeyIcon,
      VueRecaptcha,
      PhoneForwardedIcon,
      PhoneIcon,
    },
    data () {
      return {
        form: {},
        countries: [],
        loading: false,
        error: false,
        reCaptchaMissing: false,
        reCaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_KEY
      }
    },
    methods: {
      reCaptchaVerified (response) {
        this.form.g_recaptcha_response = response
      },
      reCaptchaExpired () {
        this.form.g_recaptcha_response = null
      },
      signup () {
        // validar recaptcha
        if(!this.loading) {
          this.error = false
          this.reCaptchaMissing = false
          if (this.$refs.form.checkValidity()) {
            if (!this.form.g_recaptcha_response) {
              this.reCaptchaMissing = true
              return
            }
            this.loading = true

            let cleanForm = JSON.parse(JSON.stringify(this.form));
            cleanForm.location = window.location.href.split('/').slice(0, 3).join('/')
            if (cleanForm.contact_phone_country) {
              cleanForm.contact_phone_country = [cleanForm.contact_phone_country]
            }

            duppla({ url: 'signup', method: 'POST', data: cleanForm })
            .then(response => {
              this.gTrackEvent('sign_up', 'engagement', null)
              this.$router.push('/signup/ok')
            })
            .catch(error => {
              this.loading = false
              this.error = true
              this.$refs.recaptcha.reset();
              this.form.g_recaptcha_response = null
              this.gTrackException(error)
            })
          } else {
            this.$refs.form.reportValidity();
          }
        }
      },
      getCountries () {
        duppla({ url: 'countries', method: 'GET' })
          .then(response => {
            this.countries = response.data
          })
      }
    },
    created () {
      this.getCountries()
    }
  };
</script>

<template>
  <div>
    <!--div class="back-to-home rounded d-none d-sm-block">
      <router-link to="/" class="btn btn-icon btn-primary">
        <home-icon class="icons"></home-icon>
      </router-link>
    </div-->

    <!-- Hero Start -->
    <section class="bg-home bg-circle-gradiant d-flex align-items-center">
      <div class="bg-overlay bg-overlay-white"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-8">
            <div class="card login_page shadow rounded border-0">
              <div class="card-body">
                <h4 class="card-title text-center">Iniciar Proceso</h4>
                <form class="login-form mt-4" ref="form">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label
                          >Nombre <span class="text-danger">*</span></label
                        >
                        <div class="position-relative">
                          <user-icon class="fea icon-sm icons"></user-icon>
                          <input type="text" class="form-control pl-5" placeholder="Nombre" v-model="form.contact_firstname" required/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label
                          >Apellido(s) <span class="text-danger">*</span></label
                        >
                        <div class="position-relative">
                          <user-check-icon
                            class="fea icon-sm icons"
                          ></user-check-icon>
                          <input type="text" class="form-control pl-5" placeholder="Apellido(s)" v-model="form.contact_lastname" required/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label
                          >Correo electónico <span class="text-danger">*</span></label
                        >
                        <div class="position-relative">
                          <mail-icon class="fea icon-sm icons"></mail-icon>
                          <input type="email" class="form-control pl-5" placeholder="Email" v-model="form.contact_email" required/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Lada <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <phone-forwarded-icon class="fea icon-sm icons"></phone-forwarded-icon>
                          <b-select class="form-control pl-5" v-model="form.contact_phone_country" :options="countries" required text-field="textLada" value-field="value">
                            <template #first>
                              <b-select-option :value="null" disabled>-- Lada --</b-select-option>
                            </template>
                          </b-select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group">
                          <label>Teléfono <span class="text-danger">*</span></label>
                          <div class="position-relative">
                            <phone-icon class="fea icon-sm icons"></phone-icon>
                            <input type="tel" class="form-control pl-5" placeholder="Teléfono de 10 dígitos" v-model="form.contact_phone" required maxlength="10" minlength="10" pattern="[0-9]{10}"/>
                          </div>
                        </div>
                      </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck1"
                            required
                          />
                          <label class="custom-control-label" for="customCheck1">
                            Acepto los <a href="/terminos/" target="_blank" class="text-primary">Terminos y Condiciones</a>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck2"
                            required
                          />
                          <label class="custom-control-label" for="customCheck2">
                            Acepto la <a href="/privacidad/" target="_blank" class="text-primary">Política de Privacidad</a>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                      <!--div class="g-recaptcha" data-sitekey="6Le7NUIdAAAAAHPA0ML0rQH7N2f3vR53Q_G4f2NT"></div-->
                        <vue-recaptcha :sitekey="reCaptchaSiteKey" :loadRecaptchaScript="true" @verify="reCaptchaVerified" @expired="reCaptchaExpired" ref="recaptcha"></vue-recaptcha>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <b-btn class="btn btn-primary btn-block" @click.prevent="signup" :disabled="loading">
                        {{(loading?'Registrando...':'Registrar')}}
                      </b-btn>
                    </div>
                    <div class="col-md-12 mt-3" v-if="error">
                      <div class="alert alert-danger" role="alert">Ups... ha ocurrido un error inesperado, vuelve a intentar mas tarde</div>
                    </div>
                    <div class="col-md-12 mt-3" v-if="reCaptchaMissing">
                      <div class="alert alert-danger" role="alert">Asegurate de resolver el CAPTCHA para poder continuar</div>
                    </div>
                    <!--div class="mx-auto">
                      <p class="mb-0 mt-3">
                        <small class="text-dark mr-2"
                          >¿Ya has iniciado un proceso?</small
                        >
                        <router-link
                          to="/"
                          class="text-dark font-weight-bold"
                          >Da click aquí</router-link
                        >
                      </p>
                    </div-->
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!--Switcher /-->
  </div>
</template>
  